<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle
      :title="title"
      slot="title"
      link="/helpContent/punchInOut"
    />
    <ValidationObserver ref="validator">
      <FormRow>
        <div class="col-sm-2">
          <InputDate
            label="Date"
            rules="required"
            :options="options"
            v-model="form.attendancedate"
          />
        </div>
      </FormRow>
      <FormRow>
        <div class="col-sm-4">
          <FormSelect
            label="Staff Name"
            :items="staffList"
            item-value="id"
            item-name="Name"
            rules="required"
            v-model="form.staffId"
          />
        </div>
        <div class="col-sm-3" v-if="showComp">
          <InputTime
            label="Time In"
            v-model="form.timeIn"
            rules="required"
          />
        </div>

        <div class="col-sm-3" v-if="!showComp">
          <InputTime
            label="Time Out"
            v-model="form.timeOut"
            rules="required"
            
          />
        </div>
      </FormRow>
    </ValidationObserver>
    <FormRow>
      <div class="col-md-4">
        <ButtonGroup>
          <FormButton
            type="info"
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Back</FormButton
          >
          <FormButton type="success" @click="sendData()"
            >Save</FormButton
          ></ButtonGroup
        >
      </div>
    </FormRow>
    <br />
    <Popup
      title="Success"
      type="success"
      :value="showMessage.isVisible"
      @close="() => (showMessage.isVisible = false)"
      :closable="true"
    >
      {{ showMessage.message }}
      <div slot="footer">
        <div slot="footer">
          <FormButton
            @click="
              () => {
                $router.go(-1);
              }
            "
            >Ok</FormButton
          >
        </div>
      </div>
    </Popup>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import loadingMixin from "Mixins/loadingMixin";
import {insertTimeRecord,getStaffList } from "../api";
import { ValidationObserver } from "vee-validate";
import InputDate from "Components/form/InputDate";
export default {
  name: "punch",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    PageTitle,
    ValidationObserver,
    InputDate,
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      form: {
        companyId: null,
        attendancedate: "",
        timeIn:'',
        timeOut:'',
        staffId:''
      },
      showMessage: {
        isVisible: false,
        message: "",
      },
      staffList: [],
      showComp: true,
      options: {
        disabledDate(date) {
          return date && date.valueOf() > Date.now();
        },
      },

      title:'Attendance In',
    };
  },
  watch: {
    "$route.name": function(val) {
      this.showLoader();
      setTimeout(() => {
        this.showComp = false;
        this.form = {};
        if (this.$route.name === "attendanceIn") {
         this.title='Attendance In';
        this.showComp = true;
      } else if (this.$route.name  === "attendanceOut") {
        this.title='Attendance Out';
        this.showComp = false;
      }
        this.hideLoader();
      }, 500);
    },
  },
  created() {
    if (this.$route.name === "attendanceIn") {
         this.title='Attendance In';
        this.showComp = true;
      } else if (this.$route.name  === "attendanceOut") {
        this.title='Attendance Out';
        this.showComp = false;
      }
    this.getStaffData();
    
  },
  methods: {
    getStaffData() {
      getStaffList().then((res) => {
        this.staffList = res.data;
      });
    },

    sendData() {
      this.$refs.validator.validate().then((result) => {
        if (result) {
          const data = {
            attendanceDate: this.form.attendancedate,
            timeIn: this.form.timeIn,
            timeOut: this.form.timeOut,
            staffId: this.form.staffId,
          };
          this.showLoader();
          insertTimeRecord(data)
            .then(this.handleResponse)
            .catch(this.handleError);
        }
      });
    },
    handleResponse(response) {
      this.hideLoader();
      this.showMessage.isVisible = true;
      this.showMessage.message = response.data[0].message;
      this.form.attendancedate = "";
      this.form.timeIn ="";
      this.form.timeOut = "";
      this.form = {};
    },
  },
};
</script>
